<template>
	<div class="c-app">
		<TheSidebar />
		<CWrapper>
			<TheHeader />
			<div class="c-body">
				<main class="c-main">
					<CContainer fluid class="h-100">
						<transition name="fade" mode="out-in">
							<router-view :key="$route.path" />
						</transition>
					</CContainer>
				</main>
			</div>
			<TheFooter />
		</CWrapper>
		<SvgSprite />
	</div>
</template>

<script>
export default {
	name: "TheContainer",
	components: {
		TheSidebar: () => import("./TheSidebar"),
		TheHeader: () => import("./TheHeader"),
		TheFooter: () => import("./TheFooter"),
		SvgSprite: () => import("./SvgSprite"),
	},
	watch: {
		"$store.state.modalShow": function (val) {
			if (val) {
				document.body.classList.add("modal-open")
			} else document.body.classList.remove("modal-open")
		},
	},
	created() {
		window.addEventListener("storage", this.handleStorage)
	},
	beforeDestroy() {
		window.removeEventListener("storage", this.handleStorage)
	},
	methods: {
		handleStorage() {
			const vuex = localStorage.getItem("vuex")
			if (!vuex) return this.$router.push("/login")
		},
	},
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.c-main {
	padding-top: 1rem;

	.container-fluid {
		@media (min-width: 768px) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
}
</style>
